export const darkTheme = {
      bg:"#181818",
      bgLighter:"#202020",
      text:"white",
      textSoft:"#aaaaaa",
      soft:"#373737"
}

export const lightTheme = {
    bg:"#f9f9f9",
    bgLighter:"white",
    text:"black",
    textSoft:"#606060",
    soft:"#f5f5f5"
}